<template>
  <div class="qrcodeContainer">
    <el-select filterable v-model="account">
        <el-option v-for="item in accounts" :key="item._id.$id" :label="item.account_name" :value="item._id.$id"></el-option>
    </el-select>
    <el-button @click="creatQrCode" type="primary">生成二维码</el-button>
    <div v-show="address" style="margin-top:20px">
        <vue-qr
          :size="191"
          :margin="0"
          :auto-color="true"
          :dot-scale="1"
          :text="address" />
    </div>
  </div>
</template>

<script>
import { getAccounts } from '../api/index'
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr
  },
  data () {
    return {
      accounts: [],
      account: '',
      address: ''
    }
  },
  mounted () {
    getAccounts().then(res => {
      this.accounts = res.data
    })
  },
  methods: {
    creatQrCode () {
      this.address = ''
      if (!this.account) {
        this.$message.error('请选择学校')
        return
      }
      this.address = 'http://system.positivevoice.one/#/appform?account_id=' + this.account
    }
  }
}
</script>

<style lang="scss" scoped>
    .qrcodeContainer{
        padding: 30px;
    }
.qrcode{
    width: 80px;
    height: 80px;
}
</style>
